import {authentication} from "@/services/auth";
import {Device} from "@/services/Device";
import {responses} from "@/utils/responses";
import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "@/utils/utils";


window.franchiseApi = `https://chrilantech.com/LytSoftPosOnline/api/`
// window.serverApi = `http://192.168.0.104:8080/OnlineSalesServer/`
window.serverApi = `https://overflow.abc.co.zm:8443/OnlineSalesServer/`
window.auth = authentication;
window.Device = Device;
window.responses = responses;

const EventBus = new Vue()
window.EventBus = EventBus
window.log = (val) => console.log(val);
window.Swal = Swal;
window.$utils = utils;
Vue.prototype.$utils = utils;
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
